import ZoneAPI from '../api/ZoneAPI';
import Layout from '../components/Layout';
import NoSSR from 'react-no-ssr';
import { getSelectorsByUserAgent } from 'react-device-detect';
import { useEffect, useState, Fragment } from 'react';
import { merge, bounceOutDown, fadeInUp, bounceInUp } from 'react-animations';
import styled, { keyframes, css } from 'styled-components';
import MetricsManager from '../managers/MetricsManager';
import ReactPixel from 'react-facebook-pixel';
import Container from 'react-bootstrap/Container';
import Constants from '../components/Constants';
import BeautyStars from 'beauty-stars';
import Button from 'react-bootstrap/Button';
import FormControl from 'react-bootstrap/FormControl';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import DownloadPlug from '../components/DownloadPlug';
import { AiOutlinePlayCircle } from 'react-icons/ai';
import VideoPlayerModal from '../components/VideoPlayerModal';
import { getImage } from '../managers/ImageHelper';
import ItemRow from '../components/ItemRow';
import ReactGA from 'react-ga';

const aboveFoldHeight = '700px';

const fadeInUpAnimation = keyframes`${fadeInUp}`;

const mixin = css`
  animation: 0.8s ${fadeInUpAnimation};
`;

const BouncyDiv = styled.div`
${(props) => (props.enabled() ? mixin : 'visibility: hidden')};
x`;

const styles = {
  useCaseDiv: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  centerText: {
    textAlign: 'center',
  },
};

const EtaIndex = (props) => {
  const metricsManager = new MetricsManager();

  const [selectedVibes, setSelectedVibes] = useState([]);
  const [show, setShow] = useState(false);
  const [selectedZone, setSelectedZone] = useState(props.zone);
  const [allZones, setAllZones] = useState([]);
  const zoneApi = new ZoneAPI();
  const [userY, setUserY] = useState(0);
  const [fullScreen, setFullScreen] = useState(false);

  useEffect(() => {
    ReactGA.initialize('UA-139931598-1');
    ReactGA.pageview(window.location.pathname + window.location.search);

    getAllActiveZones();
    if (selectedZone && selectedZone.slug === 'default') {
      setSelectedZone(null);
    }

    var previousY = 0;
    setInterval(() => {
      if (window.scrollY > previousY) {
        console.log(window.scrollY);
        console.log('SETTING USER Y');
        setUserY(window.scrollY);
        previousY = window.scrollY;
      }
    }, 500);
  }, []);

  async function getAllActiveZones() {
    if (!selectedZone) {
      return;
    }
    const liveZones = await zoneApi.getAllZones();
    if (liveZones && liveZones.code !== -1) {
      setAllZones(liveZones);
    }
  }

  function handleZoneChange(title) {
    const zone = allZones.find((e) => e.name === title);
    if (zone) {
      setSelectedZone(zone);
    }
  }

  const { isMobile } = getSelectorsByUserAgent(props.userAgent);

  const getInput = () => {
    return (
      <Fragment>
        <div>
          <FormControl
            as="select"
            size={isMobile ? 'md' : 'lg'}
            style={{
              color: !selectedZone
                ? 'gray'
                : selectedZone.slug === 'default'
                ? 'gray'
                : 'black',
              borderRadius: 0,
            }}
            onChange={(e) => handleZoneChange(e.target.value)}
          >
            {!selectedZone && (
              <option>{isMobile ? 'Select city..' : 'Select city'}</option>
            )}
            {selectedZone && selectedZone.slug === 'default' && (
              <option>{isMobile ? 'City..' : 'Select city'}</option>
            )}
            {selectedZone && selectedZone.slug !== 'default' && (
              <option>{selectedZone.name}</option>
            )}
            {allZones.map((item, index) => {
              if (selectedZone && selectedZone.slug === item.slug) {
                return null;
              }
              return <option>{item.name}</option>;
            })}
          </FormControl>
        </div>

        <div
          style={
            isMobile ? { display: 'flex', justifyContent: 'center' } : null
          }
        >
          <Button
            size={isMobile ? 'md' : 'lg'}
            variant="info"
            style={{ marginBottom: 0, width: isMobile ? '60px' : null }}
            onClick={() => {
              if (selectedZone) {
                window.sessionStorage.setItem(
                  'zone',
                  JSON.stringify(selectedZone),
                );
              } else {
                alert('Please select a city!');
                return;
              }
              // metricsManager.logEvent(PASSPORT_GET_STARTED_CLICK, { source: 'hero' })
              window.location.href = `/city/${selectedZone.slug}`;
            }}
          >
            {isMobile ? 'Go' : 'Plug me in'}
          </Button>
        </div>
      </Fragment>
    );
  };

  return (
    <div>
      <VideoPlayerModal
        url="https://gameplan-image-urls.s3.us-east-2.amazonaws.com/manually_uploaded/ETALaunchIgVideo_1080x1080.mov"
        showStory={fullScreen}
        handleHide={() => setFullScreen(false)}
      ></VideoPlayerModal>

      <Layout
        noContainer
        noStripe
        noDownloadHeader
        scrollColorChange={true}
        useNavHeight={false}
        style={{ backgroundColor: 'white' }}
        noNavPaddingMode
        title="ETA - Discover hip-hop, afrobeat and black experiences"
        description="ETA is your plug to the best things happening for the culture. Our seasoned guides curate events loved by locals that put you at the heart of the culture. For us by us."
        image={Constants.etaLogoUrl}
      >
        <div
          id="video-content"
          style={{
            width: '100%',
            height: aboveFoldHeight,
            overflow: 'hidden',
            backgroundColor: 'black',
          }}
        >
          {props.showVideo && (
            <NoSSR>
              <video
                playsInline
                autoPlay={true}
                muted
                loop
                src={
                  isMobile
                    ? 'https://gameplan-image-urls.s3.us-east-2.amazonaws.com/manually_uploaded/ETA-MobileView.mp4'
                    : 'https://gameplan-image-urls.s3.us-east-2.amazonaws.com/manually_uploaded/ETA-Landingp1.mp4'
                }
                style={{
                  height: aboveFoldHeight,
                  width: '100%',
                  objectFit: 'cover',
                }}
              ></video>
            </NoSSR>
          )}
        </div>

        <div
          style={{ width: '100%', justifyContent: 'center', display: 'flex' }}
        >
          <Container
            style={{
              position: 'absolute',
              zIndex: 3,
              top: 0,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: aboveFoldHeight,
            }}
          >
            <NoSSR>
              <div style={{ width: '100%', marginTop: 40 }}>
                <NoSSR>
                  <BouncyDiv enabled={() => true}>
                    <p
                      style={{
                        textAlign: 'center',
                        color: Constants.EtaButtonColorBright,
                        marginBottom: 5,
                      }}
                    >
                      <small>For us by us</small>
                    </p>
                    {/* <h1 style={{ color: 'white', fontSize: isMobile ? '2.8em' : '4em', textAlign: 'center', marginBottom: '10px' }}><strong>Find the best <span style={{ color: Constants.EtaButtonColorBright }}>moves</span></strong></h1> */}
                    <h1
                      style={{
                        color: 'white',
                        fontSize: isMobile ? '2.8em' : '4em',
                        textAlign: 'center',
                        marginBottom: '10px',
                      }}
                    >
                      <strong>
                        Tap into the{' '}
                        <span style={{ color: Constants.EtaButtonColorBright }}>
                          culture
                        </span>
                      </strong>
                    </h1>

                    <p
                      style={{
                        color: 'white',
                        fontSize: isMobile ? '1.0em' : '1.4em',
                        marginBottom: '30px',
                        textAlign: 'center',
                      }}
                    >
                      Powered by local curators, ETA plugs you into the dopest
                      things happening for the culture
                    </p>
                  </BouncyDiv>
                  {!isMobile && (
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '100%',
                      }}
                    >
                      {getInput()}
                    </div>
                  )}
                  {isMobile && (
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '100%',
                      }}
                    >
                      {getInput()}
                    </div>
                  )}
                </NoSSR>
                {/* 
                        <BouncyDiv enabled={() => true} style={{ display: 'flex', justifyContent: 'center', marginTop: 40 }}>
                            <div style={{ marginTop: 7 }}>
                                <BeautyStars
                                    size={14}
                                    value={5}
                                    inactiveColor={'#bbbb'}
                                    gap={2}
                                    editable={false}
                                />
                            </div>
                            <p style={{ color: 'white', marginLeft: 10, fontSize: '1.2em', textDecoration: "underline" }}><strong>70+ reviews</strong></p>
                        </BouncyDiv> */}

                <BouncyDiv
                  enabled={() => true}
                  style={{
                    marginTop: 25,
                    marginBottom: 0,
                    textAlign: 'center',
                  }}
                >
                  {/* <Button variant="link" style={{marginBottom: 20}}>
                                Watch the video
                            </Button> */}
                  <a onClick={() => setFullScreen(true)}>
                    <div style={{ color: 'white', marginBottom: 20 }}>
                      <p
                        style={{
                          fontSize: isMobile ? '1.0em' : '1.2em',
                          textDecoration: 'underline',
                        }}
                      >
                        <span style={{ marginRight: 5 }}>
                          <AiOutlinePlayCircle size={30} />
                        </span>
                        Watch the video
                      </p>
                    </div>
                  </a>

                  <p style={{ color: 'white', textAlign: 'center' }}>
                    <small>
                      Join over <span style={{ fontWeight: 'bold' }}>10k+</span>{' '}
                      people who have been plugged in
                    </small>
                  </p>
                </BouncyDiv>

                <DownloadPlug
                  title=""
                  subtitle=""
                  includeText={false}
                  id="homepage_hero_bottom"
                  style={{ marginTop: 0 }}
                ></DownloadPlug>
              </div>
            </NoSSR>
          </Container>
        </div>

        <NoSSR>
          <Container style={{ marginTop: 40, marginBottom: 100 }}>
            <div style={{ marginTop: '80px', marginBottom: '100px' }}>
              <BouncyDiv enabled={() => userY > 0}>
                <p style={{ textAlign: 'center', fontSize: '1.5em' }}>
                  As seen in
                </p>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginTop: '20px',
                  }}
                >
                  <div style={{ marginRight: '10%' }}>
                    <img
                      src="/static/afrotech_logo.svg"
                      style={{ width: '140px', textAlign: 'center' }}
                    />
                  </div>
                  <div>
                    <img
                      src="/static/voyagela_logo.png"
                      style={{ width: '140px', textAlign: 'center' }}
                    ></img>
                  </div>
                </div>
              </BouncyDiv>
            </div>

            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <div>
                {!isMobile && (
                  <BouncyDiv
                    enabled={() => userY > 300}
                    style={{ textAlign: 'center' }}
                  >
                    <h1
                      style={{
                        textAlign: 'center',
                        fontSize: '3em',
                        marginTop: 30,
                      }}
                    >
                      Experience authentic culture
                    </h1>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                      <p style={{ maxWidth: isMobile ? '100%' : '50%' }}>
                        ETA partners with in the know local insiders to find and
                        curate the best things happening in hip hop and black
                        culture. Updated hourly.
                      </p>
                    </div>
                  </BouncyDiv>
                )}
                <BouncyDiv
                  enabled={() => userY > 300}
                  style={{ display: 'flex', justifyContent: 'center' }}
                >
                  <img
                    src={
                      isMobile
                        ? '/static/eta_app_render_white.jpg'
                        : '/static/eta_app_render_white.jpg'
                    }
                    style={{
                      marginBottom: 20,
                      textAlign: 'center',
                      maxHeight: isMobile ? '600px' : '800px',
                    }}
                  ></img>
                </BouncyDiv>
                {isMobile && (
                  <BouncyDiv
                    enabled={() => userY > 300}
                    style={{ textAlign: 'center' }}
                  >
                    <h1 style={{ textAlign: 'center', fontSize: '2em' }}>
                      Experience authentic culture
                    </h1>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                      <p style={{ maxWidth: isMobile ? '100%' : '50%' }}>
                        ETA partners with in-the-know local insiders to find and
                        curate the best things happening in hip hop and black
                        culture. Updated hourly.
                      </p>
                    </div>
                  </BouncyDiv>
                )}
              </div>
            </div>
          </Container>
        </NoSSR>

        <Container style={{ marginTop: 20, marginBottom: 100 }}>
          <NoSSR>
            <Row
              style={{
                display: 'flex',
                marginBottom: !isMobile ? '15%' : 100,
                marginTop: '80px',
              }}
            >
              {isMobile && (
                <Col md>
                  <BouncyDiv
                    enabled={() => userY > 1200}
                    style={{
                      backgroundImage: `url(/static/background-black.jpg)`,
                      backgroundSize: 'cover',
                      width: '100%',
                      height: '300px',
                      borderRadius: '20px',
                    }}
                  />
                </Col>
              )}
              <Col
                md
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <BouncyDiv enabled={() => userY > 1200}>
                  <p
                    style={{
                      color: Constants.spacesPrimaryColor,
                      marginBottom: 8,
                      marginTop: 20,
                    }}
                  >
                    For the culture by the culture
                  </p>
                  <h2 style={{ fontSize: '2.4em', marginBottom: '20px' }}>
                    Discover the community
                  </h2>
                  <p>
                    With our city chats, you can connect with others in the
                    city, meet new people and discover experiences together.
                  </p>
                </BouncyDiv>
              </Col>
              {!isMobile && (
                <Col md style={{ textAlign: 'center' }}>
                  <BouncyDiv
                    enabled={() => userY > 1200}
                    style={{
                      backgroundImage: `url(/static/background-black.jpg)`,
                      backgroundSize: 'cover',
                      backgroundPosition: 'center',
                      maxWidth: '100%',
                      height: '300px',
                      borderRadius: '20px',
                    }}
                  ></BouncyDiv>
                </Col>
              )}
            </Row>
          </NoSSR>

          <NoSSR>
            <Row
              style={{
                display: 'flex',
                marginBottom: !isMobile ? '15%' : 50,
                marginTop: '80px',
              }}
            >
              <Col
                md
                style={{ marginLeft: 20, marginBottom: 20, minHeight: '200px' }}
              >
                <BouncyDiv
                  enabled={() => userY > 1700}
                  style={{
                    backgroundImage: `url(/static/black-owned-business.png)`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'left',
                    height: '100%',
                    borderRadius: '20px',
                  }}
                ></BouncyDiv>
              </Col>
              <Col
                md
                style={{
                  marginBottom: 100,
                  display: 'flex',
                  justifyContent: 'center',
                  minHeight: '100%',
                  alignItems: 'center',
                }}
              >
                <BouncyDiv enabled={() => userY > 1700}>
                  <p
                    style={{
                      color: Constants.spacesPrimaryColor,
                      marginBottom: 8,
                    }}
                  >
                    Help grow the local economy
                  </p>
                  <h2 style={{ fontSize: '2.4em', marginBottom: '20px' }}>
                    Support local black business
                  </h2>
                  <p>
                    ETA highlights local black-owned businesses and event
                    organizers in our calendars.
                    <br />
                    <br />
                    By using ETA you are supporting these local businesses and
                    helping them grow.
                  </p>
                </BouncyDiv>
              </Col>
            </Row>
          </NoSSR>

          <ItemRow
            items={allZones}
            getTitle={() => (
              <h2 style={{ fontSize: '2.4em' }}>Now live nationally</h2>
            )}
            subtitle={
              "We're live in over eight of the largest U.S. cities with more coming soon."
            }
            getItemRender={(item) => (
              <div style={{ marginRight: 20, cursor: 'pointer' }}>
                <a href={`/city/${item.slug}`}>
                  <div
                    style={{
                      backgroundImage: `url(${getImage(item.image)})`,
                      width: '200px',
                      height: '200px',
                      backgroundSize: 'cover',
                      backgroundPosition: 'center',
                    }}
                  ></div>
                </a>
                <a href={`/city/${item.slug}`}>
                  <p style={{ marginTop: 10, marginBottom: 0, color: 'black' }}>
                    <strong>{item.name}</strong>
                  </p>
                </a>
              </div>
            )}
            rowItemCount={4}
          />
        </Container>

        <NoSSR>
          <div
            style={{
              paddingTop: '60px',
              paddingBottom: '60px',
              backgroundColor: '#E9FCFF',
            }}
          >
            <Container>
              <h2
                style={{
                  textAlign: 'center',
                  marginBottom: '20px',
                  fontSize: '35px',
                }}
              >
                We've got fans and experience
              </h2>
              <p
                style={{
                  textAlign: 'center',
                  marginBottom: '30px',
                  color: 'gray',
                }}
              >
                We've processed over{' '}
                <span style={{ fontWeight: 'bold' }}>100k</span> in ticket sales
                and plugged in{' '}
                <span style={{ fontWeight: 'bold' }}>4,000+</span> people
              </p>
              <Row style={{ display: 'flex', justifyContent: 'center' }}>
                <Col md>
                  <p style={{ marginTop: 0 }}>
                    <span style={{ fontSize: '30px', fontWeight: 'bold' }}>
                      "
                    </span>
                    After discovering ETA, I ended up going to some events I
                    never would have heard off. I meet so many amazing people,
                    eat some great food, danced my butt off and had a blast. You
                    all really made the whole trip for me.
                    <br />
                    <span style={{ fontSize: '30px', fontWeight: 'bold' }}>
                      "
                    </span>
                  </p>
                </Col>

                <Col style={{ textAlign: !isMobile ? 'left' : 'center' }} md>
                  <img
                    src="/static/testimonial.jpg"
                    style={{ height: '300px', borderRadius: 10 }}
                  />
                  <p style={{ marginTop: '10px' }}>
                    Tina <br /> Dallas, TX
                  </p>
                </Col>
              </Row>
            </Container>
          </div>
        </NoSSR>

        <div
          style={{
            backgroundImage: `url('https://www.witheta.com/static/crowd_1.jpg')`,
            width: '100%',
            height: '400px',
            backgroundSize: 'cover',
          }}
        ></div>

        <NoSSR>
          <Container>
            <BouncyDiv
              enabled={() => userY > 3500}
              style={{ paddingBottom: 60, marginTop: 30 }}
            >
              <h2
                style={{
                  marginTop: 20,
                  marginBottom: 10,
                  fontSize: '2.6em',
                  textAlign: 'center',
                }}
              >
                The culture is waiting for you
              </h2>
              <DownloadPlug
                title=""
                subtitle=""
                includeText={false}
                id="homepage_bottom"
                style={{ marginTop: 0 }}
              ></DownloadPlug>
            </BouncyDiv>
          </Container>
        </NoSSR>
        <style jsx global>{`
          html,
          body {
            max-width: 100%;
            overflow-x: hidden;
            height: auto;
          }

          #video-content:before {
            content: '';
            position: absolute;
            background: rgba(0, 0, 0, 0.4);
            top: 0;
            height: ${aboveFoldHeight};
            right: 0;
            bottom: 0;
            left: 0;
          }
        `}</style>
      </Layout>
    </div>
  );
};

EtaIndex.getInitialProps = async (ctx) => {
  const zoneSlug = ctx.query.zone ? ctx.query.zone : 'default';
  const experience = ctx.query.exp ? ctx.query.exp : null;
  const props = { experience: experience };
  if (zoneSlug) {
    const zoneApi = new ZoneAPI();
    props.zoneSlug = zoneSlug;
    const zoneResp = await zoneApi.getZone(zoneSlug);
    if (zoneResp && zoneResp.code !== -1) {
      props.zone = zoneResp;
    }
  }
  const pageRoll = Math.random() > 0.5;

  var showVideo = true;
  if (experience === 'random') {
    const roll = Math.random() > 0.5;
    console.log(`ROLL IS ${roll}`);
    showVideo = roll;
  }
  props.exp = experience;
  props.showVideo = showVideo;

  return props;
};

export default EtaIndex;
